import React, { useState } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useStaticQuery, graphql } from 'gatsby';
import { Title, FreshChatComponent } from 'gatsby-theme-oneway-ui-components';
import { isBrowser } from 'gatsby-theme-oneway-httprequest';
import OpenChatButton from '../components/openChat';
import { Row, Col, Container } from 'react-bootstrap';

const IndexPage = () => {
	const [openChat, setOpenChat] = useState(false);

	const data = useStaticQuery(
		graphql`
			query {
				logOneway: file(relativePath: { eq: "logo-oneway.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 312
						)
					}
				}
				support: file(relativePath: { eq: "customer-service.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 150
						)
					}
				}
				shopify: file(relativePath: { eq: "shopify-logo.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 250
						)
					}
				}
				wix: file(relativePath: { eq: "wix-logo.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 130
						)
					}
				}
				bigCommerce: file(relativePath: { eq: "big-commerce-logo.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 130
						)
					}
				}
				magento: file(relativePath: { eq: "magento-logo.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 130
						)
					}
				}
				volusion: file(relativePath: { eq: "volusion-logo.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 130
						)
					}
				}
				homeGrown: file(relativePath: { eq: "home-grown-logo.png" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
							width: 200
						)
					}
				}
				survivalbox: file(relativePath: { eq: "survivalbox-img.jpg" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
						)
					}
				}
				grandbox: file(relativePath: { eq: "previous-candy-boxes-img.jpg" }) {
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
						)
					}
				}
			}
		`
	);

	const handleOpenChat = () => {
		setOpenChat(true);

		if (openChat) {
			isBrowser && window.fcWidget.open();
		}
	};

	const images = {
		logo: getImage(data.logOneway.childImageSharp.gatsbyImageData),
		support: getImage(data.support.childImageSharp.gatsbyImageData),
		shopify: getImage(data.shopify.childImageSharp.gatsbyImageData),
		wix: getImage(data.wix.childImageSharp.gatsbyImageData),
		bigCommerce: getImage(data.bigCommerce.childImageSharp.gatsbyImageData),
		magento: getImage(data.magento.childImageSharp.gatsbyImageData),
		volusion: getImage(data.volusion.childImageSharp.gatsbyImageData),
		homeGrown: getImage(data.homeGrown.childImageSharp.gatsbyImageData),
		survivalbox: getImage(data.survivalbox.childImageSharp.gatsbyImageData),
		grandbox: getImage(data.grandbox.childImageSharp.gatsbyImageData)
	};

	const chatSupport = openChat && <FreshChatComponent openChat={openChat} />;

	return (
		<>
			<GatsbySeo
				title="Oneway-ecommerce"
				description="E-commerce focused Development Studio"
				canonical="https://www.onewayecommerce.com/"
				openGraph={{
					url: 'https://www.onewayecommerce.com/',
					title: 'Oneway-ecommerce',
					description: 'We can help with every aspect of your business from tech development to operations.',
					type: 'website',
					images: [
						{
							url: 'https://survivalbox.com/images/oneway-logo-img.png',
							height: 384,
							alt: 'Oneway-ecommerce Logo'
						}
					],
					site_name: 'Oneway-ecommerce'
				}}
			/>
			{chatSupport}
			<header className="py-5" aria-labelledby="development">
				<Container className="pt-5">
					<Col className="px-0">
						<Row className="mx-0">
							<GatsbyImage image={images.logo} alt="Onewayecommerce Logo" className="mx-auto" />
						</Row>
						<Col className="px-0 pt-5 text-center">
							<Title
								as="h1"
								id="development"
								title="E-commerce focused Development Studio"
								color="dark"
								customClass="pb-3"
							/>
							<p className="text-onewayecommerce-style">
								We partner with you to design, develop and scale your e-commerce company, let's build
								the next generation of electronic stores.
							</p>
						</Col>
					</Col>
				</Container>
			</header>
			<section className="py-5" aria-labelledby="support">
				<Container>
					<Col className="px-0">
						<Row className="mx-0">
							<GatsbyImage
								image={images.support}
								alt="Personal Support"
								className="mx-auto"
								style={{ borderRadius: '110px' }}
							/>
						</Row>
						<Row className="mx-0">
							<Col lg={8} md={8} sm={12} xs={12} className="px-0 text-center pt-3 mx-auto">
								<Title
									as="h2"
									id="support"
									title="Want to build or Scale with us?"
									color="dark"
									customClass="pb-3"
								/>
								<p className="text-onewayecommerce-style">
									We can help with every aspect of your business from tech development to operations.
								</p>
							</Col>
						</Row>
						<OpenChatButton title="CHAT WITH US" clickAction={handleOpenChat} />
					</Col>
				</Container>
			</section>
			<section className="py-5" aria-labelledby="existing-store">
				<Container>
					<Col className="px-0">
						<Title
							as="h2"
							id="existing-store"
							align="center"
							title="Want to scale your existing store?"
							color="dark"
							customClass="pb-5"
						/>
						<Row className="mx-0 mb-5">
							<Col lg={4} md={4} sm={12} xs={12} className="text-center">
								<GatsbyImage
									image={images.shopify}
									alt="Shopify Logo"
									style={{ borderRadius: '110px' }}
								/>
								<Title as="h3" title="Shopify" color="dark" />
								<p>
									Looking to scale build or redesign. We design and develop custom Shopify websites
									and take it to the next level out of the un scalable pluggings.
								</p>
							</Col>
							<Col lg={4} md={4} sm={12} xs={12} className="text-center">
								<GatsbyImage image={images.wix} alt="Wix Logo" style={{ marginTop: '60px' }} />
								<div className="pt-3">
									<Title as="h3" title="Wix" color="dark" customClass="pt-5" />
									<p>
										Wix was a good MVP Now you are looking to scale , we are experts e-commerce
										development agency we can help with it.
									</p>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12} xs={12} className="text-center">
								<GatsbyImage
									image={images.bigCommerce}
									alt="Bigcommerce Logo"
									style={{ marginTop: '20px' }}
								/>
								<div>
									<Title as="h3" title="Bigcommerce" color="dark" customClass="pt-4" />
									<p>
										BigCommerce offers hundreds of apps to grow your operations, but sometimes you
										need a real scalable solution . we can handle big commerce apps.
									</p>
								</div>
							</Col>
						</Row>
						<Row className="mx-0">
							<Col lg={4} md={4} sm={12} xs={12} className="text-center">
								<GatsbyImage image={images.magento} alt="Magento Logo" style={{ marginTop: '10px' }} />
								<Title as="h3" title="Shopify" color="dark" customClass="pt-4 mt-2" />
								<p>
									You are a grown up company now with millions of clients, our army of Magento's
									Engineers will help your B2C or B2B eCommerce grow to the next level
								</p>
							</Col>
							<Col lg={4} md={4} sm={12} xs={12} className="text-center">
								<GatsbyImage
									image={images.volusion}
									alt="Volusion Logo"
									style={{ marginTop: '30px' }}
								/>
								<Title as="h3" title="Volusion" color="dark" customClass="pt-5" />
								<p>
									Our Volusion engineer experts will developers, and designers a professional
									enterprise e-commerce experience using best practice on SEO and performance
								</p>
							</Col>
							<Col lg={4} md={4} sm={12} xs={12} className="text-center">
								<GatsbyImage
									image={images.homeGrown}
									alt="Home-grown Logo"
									style={{ marginTop: '30px' }}
								/>
								<div className="pt-4">
									<Title as="h3" title="Home Grown" color="dark" customClass="pt-4" />
									<p>
										We can develop a costume store that handle the most complex technical scenarios
										and business models, Subscriptions, recurring payments and many more.
									</p>
								</div>
							</Col>
						</Row>
					</Col>
				</Container>
			</section>
			<footer className="pt-5">
				<Container className="text-center pt-5 pb-3">
					<Title as="h5" title="Copyright © 2020. All rights reserved." color="dark" />
				</Container>
			</footer>
		</>
	);
};

export default IndexPage;
